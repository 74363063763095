(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-draw-racing/views/item.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-draw-racing/views/item.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.griditem_draw_racing=_svs.banner_layouts.griditem_draw_racing||{};
_svs.banner_layouts.griditem_draw_racing.templates=_svs.banner_layouts.griditem_draw_racing.templates||{};
svs.banner_layouts.griditem_draw_racing.templates.item = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div\n    class=\"banner-griditem-draw-racing banner-common-griditem "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":62},"end":{"line":3,"column":157}}})) != null ? stack1 : "")
    + " f-content\"\n    data-impression=\"true\"\n    data-impression-tracking=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":48}}}) : helper)))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  >\n    <div class=\"banner-background-clip\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0),"!==","0",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":25,"column":16}}})) != null ? stack1 : "")
    + "    <div class=\"banner-main-content banner-main-padding-1\">\n      <div class=\"banner-main-text\">\n        <div class=\"item-draw-racing-funds\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"logoText") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":33,"column":17}}})) != null ? stack1 : "")
    + "          <div class=\"f-medium item-draw-racing-funds-title notranslate\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":34,"column":73},"end":{"line":34,"column":84}}}) : helper))) != null ? stack1 : "")
    + "</div>\n            <div class=\"item-draw-racing-funds-subtitle\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayTrackAndTime") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":60,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayBannerText") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":67,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "banner-griditem-branding-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"branding") || (depth0 != null ? lookupProperty(depth0,"branding") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"branding","hash":{},"data":data,"loc":{"start":{"line":3,"column":103},"end":{"line":3,"column":115}}}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return "banner-griditem-no-branding";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    data-cmsid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":28}}}) : helper)))
    + "\"\n    data-cmstype=\"banner\"\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"banner-background-landscape\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"banner-background-portrait\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-griditem-gradient banner-griditem-gradient-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gradientOpacity") || (depth0 != null ? lookupProperty(depth0,"gradientOpacity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gradientOpacity","hash":{},"data":data,"loc":{"start":{"line":24,"column":66},"end":{"line":24,"column":85}}}) : helper)))
    + "\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"item-draw-racing-funds-logo\">\n              "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"typeOfSpeechBubble") : depth0),{"name":"ui","hash":{"rotate":(depth0 != null ? lookupProperty(depth0,"speechBubbleRotation") : depth0),"size":"200","branding":"default"},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":31,"column":117}}})) != null ? stack1 : "")
    + "\n            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"logoText") || (depth0 != null ? lookupProperty(depth0,"logoText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"logoText","hash":{},"data":data,"loc":{"start":{"line":31,"column":98},"end":{"line":31,"column":110}}}) : helper)));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"subtitle-flag\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayTrackAndTime") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":18},"end":{"line":40,"column":25}}})) != null ? stack1 : "")
    + "                </span>\n                <span class=\"subtitle-text\">\n                  "
    + alias4(((helper = (helper = lookupProperty(helpers,"trackName") || (depth0 != null ? lookupProperty(depth0,"trackName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackName","hash":{},"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":43,"column":31}}}) : helper)))
    + "<br/>\n                  "
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":44,"column":18},"end":{"line":44,"column":31}}}) : helper)))
    + "\n                </span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"lb-ui")||(depth0 && lookupProperty(depth0,"lb-ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"flags",{"name":"lb-ui","hash":{"iso":(depth0 != null ? lookupProperty(depth0,"countryCode") : depth0)},"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":39,"column":53}}}))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"subtitle-text\">\n                  "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":48,"column":18},"end":{"line":48,"column":30}}}) : helper)))
    + "\n                </span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-header\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-tablet","heightMode":"tablet","branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"emblem":"true"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-product_header-product_header"),depth0,{"name":"components-lb_ui-product_header-product_header","hash":{"class":"banner-header-desktop","heightMode":"desktop","branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0),"emblem":"true"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"ribbon-holder\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ribbon/banner",{"name":"ui","hash":{"size":(depth0 != null ? lookupProperty(depth0,"ribbonSize") : depth0),"direction":"left"},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":65,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bannerText") || (depth0 != null ? lookupProperty(depth0,"bannerText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bannerText","hash":{},"data":data,"loc":{"start":{"line":64,"column":10},"end":{"line":64,"column":24}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-link-link"),depth0,{"name":"components-banner_actions-link-link","hash":{"clickTrackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"action":(depth0 != null ? lookupProperty(depth0,"linkActionConfig") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_layouts-griditem_draw_racing-item'] = svs.banner_layouts.griditem_draw_racing.templates.item;
})(svs, Handlebars);


 })(window);